import React from 'react';
import { Link } from 'react-router-dom';
import { Zap, Code, Cpu, Layers } from 'lucide-react';
import ParticleBackground from './ParticleBackground';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 flex flex-col items-center text-center">
    <Icon className="h-12 w-12 text-indigo-400 mb-4" />
    <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 to-indigo-900 text-white relative overflow-hidden">
      <ParticleBackground />
      <div className="container mx-auto px-4 py-16 relative z-10">
        <header className="text-center mb-16">
          <h1 className="text-5xl md:text-6xl font-bold mb-4">Welcome to Sensara</h1>
          <p className="text-xl md:text-2xl text-gray-300 mb-8">Empowering Your App Development Journey</p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/register"
              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-6 rounded-full transition duration-300"
            >
              Get Started
            </Link>
            <Link
              to="/login"
              className="bg-transparent hover:bg-white hover:text-indigo-900 text-white font-bold py-2 px-6 rounded-full border border-white transition duration-300"
            >
              Log In
            </Link>
          </div>
        </header>
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          <FeatureCard
            icon={Zap}
            title="Lightning Fast"
            description="Accelerate your app development process with our intuitive tools and workflows."
          />
          <FeatureCard
            icon={Code}
            title="Smart Coding"
            description="Leverage AI-powered code suggestions and auto-completion for efficient development."
          />
          <FeatureCard
            icon={Cpu}
            title="Powerful Analytics"
            description="Gain insights into your app's performance and user behavior with advanced analytics."
          />
          <FeatureCard
            icon={Layers}
            title="Scalable Architecture"
            description="Build apps that can grow with your business using our scalable infrastructure."
          />
        </section>
        <section className="text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Ideas into Reality?</h2>
          <p className="text-xl text-gray-300 mb-8">Join thousands of developers who trust Sensara for their app development needs.</p>
          <Link
            to="/register"
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300"
          >
            Start Your Journey
          </Link>
        </section>
      </div>
    </div>
  );
};

export default LandingPage;