import { Lightbulb, Users, List, Zap, Palette, Layers, GitBranch, Globe, User, Smartphone } from 'lucide-react';

const questions = [
  {
    id: 'userName',
    question: "What's your name?",
    helpText: 'Please enter your full name.',
    type: 'textarea',
    icon: <User size={40} className="text-blue-400" />,
    placeholder: 'e.g., John Doe'
  },
  {
    id: 'appName',
    question: "What's the name of your app?",
    helpText: 'Enter the name you\'ve chosen for your app.',
    type: 'textarea',
    icon: <Smartphone size={40} className="text-purple-400" />,
    placeholder: 'e.g., TaskMaster'
  },
  {
    id: 'appPurpose',
    question: 'What does your app do? How does it help people?',
    helpText: 'Describe your app idea in simple terms. What problem does it solve?',
    type: 'textarea',
    icon: <Lightbulb size={40} className="text-yellow-400" />,
    placeholder: 'Example: My app helps people find dog walkers in their neighborhood.'
  },
  {
    id: 'targetAudience',
    question: 'Who will use your app?',
    helpText: 'Think about the age, interests, or needs of the people who would use your app.',
    type: 'textarea',
    icon: <Users size={40} className="text-green-400" />,
    placeholder: 'Example: Dog owners who work full-time and need help walking their dogs.'
  },
  {
    id: 'coreFeatures',
    question: 'What are the main things your app can do?',
    helpText: 'List the key features of your app. Add as many as you need.',
    type: 'dynamicInput',
    icon: <List size={40} className="text-blue-400" />,
    placeholder: 'Enter a feature of your app',
    addButtonText: 'Add another feature'
  },
  {
    id: 'uniqueSellingPoint',
    question: 'What makes your app special or different from others?',
    helpText: 'Think about why someone would choose your app over similar ones.',
    type: 'textarea',
    icon: <Zap size={40} className="text-purple-400" />,
    placeholder: 'Example: Our app connects dog owners with trusted local dog walkers who have been background-checked.'
  },
  {
    id: 'keyScreens',
    question: 'What are the main screens or pages in your app?',
    helpText: 'List the important screens your app should have. Add as many as you need.',
    type: 'dynamicInput',
    icon: <Layers size={40} className="text-indigo-400" />,
    placeholder: 'Enter a screen or page name',
    addButtonText: 'Add another screen'
  },
  {
    id: 'designPreference',
    question: 'What style or look do you want for your app?',
    helpText: 'Choose the option that best describes your preferred app design.',
    type: 'select',
    options: [
      'Clean and simple',
      'Bright and colorful',
      'Professional and sleek',
      'Fun and playful',
      'Elegant and luxurious',
      'Not sure (would like designer\'s input)',
      'Other (please describe)'
    ],
    icon: <Palette size={40} className="text-pink-400" />,
  },
  {
    id: 'colorScheme',
    question: 'What colors would you like to use in your app?',
    helpText: 'Select up to 5 colors that you think would work well for your app.',
    type: 'colorPicker',
    icon: <Palette size={40} className="text-pink-400" />,
    maxColors: 5,
  },

  {
    id: 'userFlows',
    question: 'What are the most important things users should be able to do in your app?',
    helpText: 'Describe the main actions or tasks users will perform in your app.',
    type: 'textarea',
    icon: <GitBranch size={40} className="text-cyan-400" />,
    placeholder: 'Example: 1. Find a nearby dog walker 2. Book a walking appointment 3. Pay for the service 4. Rate the dog walker'
  },
  {
    id: 'platforms',
    question: 'On which platforms do you want your app to work?',
    helpText: 'Select all the platforms where you want your app to be available.',
    type: 'checkbox',
    options: [
      'iPhone (iOS)',
      'Android phones',
      'iPad',
      'Android tablets',
      'Web browsers',
      'Smart watches',
      'Other (please specify)'
    ],
    icon: <Globe size={40} className="text-blue-400" />,
  },
  {
    id: 'additionalInfo',
    question: 'Is there anything else you\'d like to tell us about your app idea?',
    helpText: 'Share any other thoughts or ideas you have about your app.',
    type: 'textarea',
    icon: <Lightbulb size={40} className="text-yellow-400" />,
    placeholder: 'Example: I\'d like the app to have a feature that lets dog owners see real-time updates of their dog\'s walk.'
  },
];

export default questions;