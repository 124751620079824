import React from 'react';
import { XCircle } from 'lucide-react';

const HelpModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white text-gray-800 p-8 rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Need Help?</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <XCircle size={24} />
          </button>
        </div>
        <div className="space-y-4">
          <p>Don't worry! Here are some tips to help you fill out the questionnaire:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>There are no right or wrong answers. We want to hear your ideas!</li>
            <li>If you're not sure about something, it's okay to say "I'm not sure" or "I haven't thought about that yet."</li>
            <li>You can always go back and change your answers using the "Previous" button.</li>
            <li>Take your time and have fun imagining your app!</li>
          </ul>
          <p>If you need more help, feel free to contact our support team at support@yourcompany.com</p>
        </div>
      </div>
    </div>
  );
};

export default HelpModal;