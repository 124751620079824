import React, { useState, useEffect } from 'react';
import './ParticleBackground.css'; // Assuming you have the necessary CSS

const ParticleBackground = () => {
  const [particles, setParticles] = useState([]);

  useEffect(() => {
    const particleCount = 50;
    const newParticles = [];

    for (let i = 0; i < particleCount; i++) {
      newParticles.push({
        id: i,
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 5 + 1,
        duration: Math.random() * 20 + 10
      });
    }

    setParticles(newParticles);
  }, []);

  return (
    <div className="particle-container">
      {particles.map((particle) => (
        <div
          key={particle.id}
          className="particle"
          style={{
            left: `${particle.x}%`,
            top: `${particle.y}%`,
            width: `${particle.size}px`,
            height: `${particle.size}px`,
            animation: `particleFloat ${particle.duration}s infinite ease-in-out`
          }}
        />
      ))}
    </div>
  );
};

export default ParticleBackground;