import React, { forwardRef } from 'react';

const TextAreaQuestion = forwardRef(({ question, value, onChange, onKeyPress }, ref) => (
  <textarea
    ref={ref}
    className="w-full p-4 bg-gray-800 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
    rows="6"
    value={value || ''}
    onChange={(e) => onChange(question.id, e.target.value)}
    onKeyPress={onKeyPress}
    placeholder={question.placeholder}
  />
));

const SelectQuestion = ({ question, value, onChange }) => (
  <div className="grid grid-cols-2 gap-4">
    {question.options.map((option) => (
      <button
        key={option}
        className={`p-4 rounded-lg transition-all duration-300 ${
          value === option
            ? 'bg-blue-600 text-white'
            : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
        }`}
        onClick={() => onChange(question.id, option)}
      >
        {option}
      </button>
    ))}
  </div>
);

const CheckboxQuestion = ({ question, value, onChange }) => (
  <div className="grid grid-cols-2 gap-4">
    {question.options.map((option) => (
      <label key={option} className="flex items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          checked={(value || []).includes(option)}
          onChange={(e) => {
            const currentOptions = value || [];
            const newOptions = e.target.checked
              ? [...currentOptions, option]
              : currentOptions.filter((o) => o !== option);
            onChange(question.id, newOptions);
          }}
          className="form-checkbox h-5 w-5 text-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
        <span className="text-white">{option}</span>
      </label>
    ))}
  </div>
);

export { TextAreaQuestion, SelectQuestion, CheckboxQuestion };