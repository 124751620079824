// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { AppDetailsProvider } from './contexts/AppDetailsContext';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Register from './components/Register';
import AdminRegister from './components/AdminRegister';
import AppDevelopmentProcess from './components/AppDevelopmentProcess';
import AppIdeaQuestionnaire from './components/AppIdeaQuestionnaire';
import ResultsDisplay from './components/ResultsDisplay';
import AdminPanel from './components/AdminPanel';
import Onboarding from './components/Onboarding';
import LandingPage from './components/LandingPage';
function PrivateRoute({ children }) {
  const { currentUser, authLoading } = useAuth();
  if (authLoading) {
    return null; // Or a loading indicator
  }
  return currentUser ? children : <Navigate to="/login" />;
}

function AdminRoute({ children }) {
  const { currentUser, isAdmin, authLoading } = useAuth();
  if (authLoading) {
    return null; // Or a loading indicator
  }
  if (!currentUser) {
    return <Navigate to="/login" />;
  }
  if (!isAdmin) {
    return <Navigate to="/" />;
  }
  return children;
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppDetailsProvider>
          <div className="min-h-screen bg-gray-900">
            <Navbar />
            <div className="pt-16">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/" element={<LandingPage />} />

                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <AppDevelopmentProcess />
                    </PrivateRoute>
                  }
                />
                <Route path="/onboarding" element={<PrivateRoute><Onboarding /></PrivateRoute>} />
                <Route
                  path="/questionnaire"
                  element={
                    <PrivateRoute>
                      <AppIdeaQuestionnaire />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/results"
                  element={
                    <PrivateRoute>
                      <ResultsDisplay />
                    </PrivateRoute>
                  }
                />
                <Route path="/admin/register" element={<AdminRegister />} />
                <Route
                  path="/admin"
                  element={
                    <AdminRoute>
                      <AdminPanel />
                    </AdminRoute>
                  }
                />
              </Routes>
            </div>
          </div>
        </AppDetailsProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
