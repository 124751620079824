import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Check } from 'lucide-react';

const OnboardingStep = ({ title, description, children, isCompleted }) => (
  <div className={`bg-white shadow-md rounded-lg p-6 mb-4 ${isCompleted ? 'border-2 border-green-500' : ''}`}>
    <h3 className="text-lg font-semibold mb-2 flex items-center">
      {title}
      {isCompleted && <Check className="ml-2 text-green-500" size={20} />}
    </h3>
    <p className="text-gray-600 mb-4">{description}</p>
    {children}
  </div>
);

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [profileInfo, setProfileInfo] = useState({ company: '', role: '', interests: [] });
  const { currentUser, updateUserProfile } = useAuth();
  const navigate = useNavigate();

  const handleProfileInfoChange = (e) => {
    setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value });
  };

  const handleInterestToggle = (interest) => {
    setProfileInfo(prevState => ({
      ...prevState,
      interests: prevState.interests.includes(interest)
        ? prevState.interests.filter(i => i !== interest)
        : [...prevState.interests, interest]
    }));
  };

  const handleNextStep = async () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      try {
        await updateUserProfile(currentUser.uid, profileInfo);
        navigate('/dashboard');
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const interests = ['Web Development', 'Mobile Apps', 'Data Science', 'AI/ML', 'DevOps', 'Cybersecurity'];

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-600 to-indigo-500 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-center text-3xl font-extrabold text-white mb-8">
          Welcome to Your SAAS Journey
        </h2>
        <div className="space-y-6">
          <OnboardingStep
            title="Complete Your Profile"
            description="Tell us a bit more about yourself to personalize your experience."
            isCompleted={step > 1}
          >
            {step === 1 && (
              <div className="space-y-4">
                <div>
                  <label htmlFor="company" className="block text-sm font-medium text-gray-700">Company</label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={profileInfo.company}
                    onChange={handleProfileInfoChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    value={profileInfo.role}
                    onChange={handleProfileInfoChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            )}
          </OnboardingStep>

          <OnboardingStep
            title="Select Your Interests"
            description="Choose topics you're interested in to customize your dashboard."
            isCompleted={step > 2}
          >
            {step === 2 && (
              <div className="grid grid-cols-2 gap-4">
                {interests.map((interest) => (
                  <button
                    key={interest}
                    onClick={() => handleInterestToggle(interest)}
                    className={`py-2 px-4 rounded-md text-sm font-medium ${
                      profileInfo.interests.includes(interest)
                        ? 'bg-indigo-600 text-white'
                        : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                    }`}
                  >
                    {interest}
                  </button>
                ))}
              </div>
            )}
          </OnboardingStep>

          <OnboardingStep
            title="Set Up Your Workspace"
            description="Customize your workspace to boost your productivity."
            isCompleted={step > 3}
          >
            {step === 3 && (
              <div className="text-center">
                <p className="text-gray-600 mb-4">Your workspace is ready! Click 'Finish' to access your dashboard.</p>
              </div>
            )}
          </OnboardingStep>

          <div className="flex justify-end">
            <button
              onClick={handleNextStep}
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {step === 3 ? 'Finish' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;