import React, { useState } from 'react';
import { X } from 'lucide-react';

const colorOptions = [
  { name: 'Red', value: '#FF0000' },
  { name: 'Blue', value: '#0000FF' },
  { name: 'Green', value: '#00FF00' },
  { name: 'Yellow', value: '#FFFF00' },
  { name: 'Purple', value: '#800080' },
  { name: 'Orange', value: '#FFA500' },
  { name: 'Pink', value: '#FFC0CB' },
  { name: 'Teal', value: '#008080' },
  { name: 'Brown', value: '#A52A2A' },
  { name: 'Gray', value: '#808080' },
  { name: 'Navy', value: '#000080' },
  { name: 'Lime', value: '#00FF00' },
  { name: 'Cyan', value: '#00FFFF' },
  { name: 'Magenta', value: '#FF00FF' },
  { name: 'Olive', value: '#808000' },
];

const ColorPickerQuestion = ({ question, value, onChange }) => {
  const [selectedColors, setSelectedColors] = useState(value || []);

  const handleColorClick = (color) => {
    if (selectedColors.includes(color)) {
      setSelectedColors(selectedColors.filter(c => c !== color));
    } else if (selectedColors.length < question.maxColors) {
      setSelectedColors([...selectedColors, color]);
    }
    onChange(question.id, selectedColors);
  };

  const removeColor = (color) => {
    setSelectedColors(selectedColors.filter(c => c !== color));
    onChange(question.id, selectedColors.filter(c => c !== color));
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-2">
        {selectedColors.map(color => (
          <div key={color.name} className="flex items-center bg-gray-700 rounded-full pl-3 pr-1 py-1">
            <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: color.value }}></div>
            <span className="text-sm">{color.name}</span>
            <button
              onClick={() => removeColor(color)}
              className="ml-2 text-gray-400 hover:text-white focus:outline-none"
            >
              <X size={16} />
            </button>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-5 gap-2">
        {colorOptions.map(color => (
          <button
            key={color.name}
            onClick={() => handleColorClick(color)}
            className={`w-full p-2 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white
              ${selectedColors.includes(color) ? 'ring-2 ring-white' : ''}
              hover:opacity-80`}
            style={{ backgroundColor: color.value }}
            disabled={selectedColors.length >= question.maxColors && !selectedColors.includes(color)}
          >
            <span className="sr-only">{color.name}</span>
          </button>
        ))}
      </div>
      <p className="text-sm text-gray-400">
        {selectedColors.length} of {question.maxColors} colors selected
      </p>
    </div>
  );
};

export default ColorPickerQuestion;