import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Zap, User as UserIcon, LogOut, LogIn, UserPlus, ChevronDown } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useAppDetails } from '../contexts/AppDetailsContext';

const Navbar = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { currentUser, logout, authLoading } = useAuth();
  const { clearLocalData } = useAppDetails();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Close the user menu when the location changes
    setIsUserMenuOpen(false);
  }, [location]);

  useEffect(() => {
    // Close the user menu when the current user changes
    setIsUserMenuOpen(false);
  }, [currentUser]);

  if (authLoading) {
    return null; // Or a placeholder navbar
  }

  const handleLogout = async () => {
    try {
      await logout();
      clearLocalData(); // Ensure this function exists
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 px-6 py-4 flex justify-between items-center bg-gray-900 bg-opacity-80 backdrop-filter backdrop-blur-lg">
      <Link to="/" className="text-white text-2xl font-light">
        <Zap size={24} className="inline-block mr-2" />
        Sensara
      </Link>
      <div className="flex items-center space-x-6">
        <Link to="/dashboard" className="text-white hover:text-gray-300 transition-colors">
          Dashboard
        </Link>
        {currentUser ? (
          <div className="relative">
            <button
              onClick={toggleUserMenu}
              className="text-white hover:text-gray-300 transition-colors flex items-center"
            >
              <UserIcon size={20} className="mr-2" />
              {currentUser.email}
              <ChevronDown size={16} className="ml-1" />
            </button>
            {isUserMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md overflow-hidden shadow-xl z-10">
                <button
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-700 w-full text-left"
                >
                  <LogOut size={16} className="inline-block mr-2" />
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <Link to="/login" className="text-white hover:text-gray-300 transition-colors">
              <LogIn size={20} className="inline-block mr-1" />
              Login
            </Link>
            <Link to="/register" className="text-white hover:text-gray-300 transition-colors">
              <UserPlus size={20} className="inline-block mr-1" />
              Register
            </Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;