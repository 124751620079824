// AppDetailsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';

const AppDetailsContext = createContext();

export function useAppDetails() {
  return useContext(AppDetailsContext);
}

export function AppDetailsProvider({ children }) {
  const { currentUser, loading: authLoading } = useAuth();
  const [appDetails, setAppDetails] = useState(null);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [approvedSteps, setApprovedSteps] = useState([]);
  const [stepFiles, setStepFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (authLoading) return;

    if (!currentUser) {
      // User has logged out, reset state
      resetState();
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setAppDetails(data.appDetails || null);
          setCompletedSteps(data.completedSteps || []);
          setApprovedSteps(data.approvedSteps || []);
          setStepFiles(data.stepFiles || {});
        } else {
          // No user data found, reset state
          resetState();
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
        setDataFetched(true);
      }
    };

    fetchData();
  }, [currentUser, authLoading]);

  useEffect(() => {
    if (authLoading || !dataFetched || !currentUser) return;

    const saveData = async () => {
      try {
        const docRef = doc(db, 'users', currentUser.uid);
        await setDoc(
          docRef,
          {
            appDetails,
            completedSteps,
            approvedSteps,
            stepFiles,
          },
          { merge: true }
        );
      } catch (error) {
        console.error('Error saving user data:', error);
      }
    };

    saveData();
  }, [appDetails, completedSteps, approvedSteps, stepFiles, currentUser, authLoading, dataFetched]);

  const updateCompletedSteps = async (stepName, isCompleted) => {
    if (authLoading || !currentUser) return;

    const newCompletedSteps = isCompleted
      ? [...new Set([...completedSteps, stepName])]
      : completedSteps.filter((step) => step !== stepName);

    setCompletedSteps(newCompletedSteps);

    try {
      const docRef = doc(db, 'users', currentUser.uid);
      await updateDoc(docRef, { completedSteps: newCompletedSteps });
    } catch (error) {
      console.error('Error updating completed steps:', error);
    }
  };

  const updateApprovedSteps = (stepName, isApproved) => {
    setApprovedSteps((prev) => {
      if (isApproved && !prev.includes(stepName)) {
        return [...prev, stepName];
      } else if (!isApproved) {
        return prev.filter((step) => step !== stepName);
      }
      return prev;
    });
  };

  const updateStepFile = (stepName, fileUrl) => {
    setStepFiles((prev) => ({
      ...prev,
      [stepName]: fileUrl,
    }));
  };

  const resetQuestionnaire = async () => {
    setAppDetails(null);
    setCompletedSteps((prev) => prev.filter((step) => step !== 'Ignition Questionnaire'));
    setApprovedSteps([]);
    setStepFiles({});

    if (currentUser) {
      try {
        const docRef = doc(db, 'users', currentUser.uid);
        await updateDoc(docRef, {
          appDetails: null,
          completedSteps: completedSteps.filter((step) => step !== 'Ignition Questionnaire'),
          approvedSteps: [],
          stepFiles: {},
        });
      } catch (error) {
        console.error('Error resetting questionnaire data:', error);
      }
    }
  };

  const clearLocalData = () => {
    resetState();
    setLoading(true);
    setDataFetched(false);
  };

  const resetState = () => {
    setAppDetails(null);
    setCompletedSteps([]);
    setApprovedSteps([]);
    setStepFiles({});
  };

  const value = {
    appDetails,
    setAppDetails,
    completedSteps,
    updateCompletedSteps,
    approvedSteps,
    updateApprovedSteps,
    stepFiles,
    updateStepFile,
    resetQuestionnaire,
    loading,
    clearLocalData,
  };

  return <AppDetailsContext.Provider value={value}>{children}</AppDetailsContext.Provider>;
}