// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC7MvMainFZ3Fla-aA37K6ifOVO-ag-Y_Y",
  authDomain: "sensaraignite.firebaseapp.com",
  projectId: "sensaraignite",
  storageBucket: "sensaraignite.appspot.com",
  messagingSenderId: "431710214895",
  appId: "1:431710214895:web:1ee1224c81d791bfd1191a",
  measurementId: "G-Z780LE7YSH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { auth, db, storage, analytics };
