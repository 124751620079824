// DynamicInputQuestion.js
import React, { useState, useRef, useEffect } from 'react';
import { Plus, X } from 'lucide-react';

const DynamicInputQuestion = ({ question, value, onChange }) => {
  const [inputs, setInputs] = useState(value || ['']);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[inputs.length - 1]) {
      inputRefs.current[inputs.length - 1].focus();
    }
  }, [inputs.length]);

  const handleInputChange = (index, newValue) => {
    const newInputs = [...inputs];
    newInputs[index] = newValue;
    setInputs(newInputs);
    onChange(question.id, newInputs.filter(input => input.trim() !== ''));
  };

  const addInput = () => {
    setInputs([...inputs, '']);
  };

  const removeInput = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
    onChange(question.id, newInputs.filter(input => input.trim() !== ''));
  };

  return (
    <div className="space-y-2">
      {inputs.map((input, index) => (
        <div key={index} className="flex items-center space-x-2">
          <input
            type="text"
            value={input}
            onChange={(e) => handleInputChange(index, e.target.value)}
            placeholder={question.placeholder}
            className="flex-grow p-2 bg-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:outline-none"
            ref={el => inputRefs.current[index] = el}
          />
          {index > 0 && (
            <button
              onClick={() => removeInput(index)}
              className="p-2 text-gray-400 hover:text-white focus:outline-none"
            >
              <X size={20} />
            </button>
          )}
        </div>
      ))}
      <button
        onClick={addInput}
        className="mt-2 flex items-center space-x-2 text-blue-400 hover:text-blue-300 focus:outline-none"
      >
        <Plus size={20} />
        <span>{question.addButtonText}</span>
      </button>
    </div>
  );
};

export default DynamicInputQuestion;