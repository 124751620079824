import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, ArrowRight, HelpCircle, Check } from 'lucide-react';
import { TextAreaQuestion, SelectQuestion, CheckboxQuestion } from './QuestionComponents';
import ParticleBackground from './ParticleBackground';
import questions from './questions';
import DynamicInputQuestion from './DynamicInputQuestion';
import ColorPickerQuestion from './ColorPickerQuestion';
import HelpModal from './HelpModal';
import { useAppDetails } from '../contexts/AppDetailsContext';
import { useAuth } from '../contexts/AuthContext';

const AppIdeaQuestionnaire = () => {
  const navigate = useNavigate();
  const { setAppDetails, updateCompletedSteps } = useAppDetails();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const textAreaRef = useRef(null);
  const { currentUser, authLoading } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (textAreaRef.current && questions[currentQuestion].type === 'textarea') {
      textAreaRef.current.focus();
    }
  }, [currentQuestion]);

  const handleSubmit = async () => {
    if (authLoading || !currentUser) {
      console.error('Cannot submit questionnaire: User is not authenticated.');
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await fetch('https://humdrum-enchanted-fish.glitch.me/submit-qa', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          questionsAndAnswers: Object.entries(answers).map(([id, answer]) => ({
            question: questions.find((q) => q.id === id).question,
            answer,
          })),
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate app details');
      }

      const data = await response.json();

      setAppDetails(data.response);
      await updateCompletedSteps('Ignition Questionnaire', true);
      navigate('/results');
    } catch (error) {
      console.error('Error submitting questionnaire:', error);
      // Handle error (e.g., display a message to the user)
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAnswer = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleNext();
    }
  };

  const renderQuestion = (question) => {
    switch (question.type) {
      case 'textarea':
        return (
          <TextAreaQuestion
            ref={textAreaRef}
            question={question}
            value={answers[question.id]}
            onChange={handleAnswer}
            onKeyPress={handleKeyPress}
          />
        );
      case 'select':
        return (
          <SelectQuestion
            question={question}
            value={answers[question.id]}
            onChange={handleAnswer}
          />
        );
      case 'checkbox':
        return (
          <CheckboxQuestion
            question={question}
            value={answers[question.id]}
            onChange={handleAnswer}
          />
        );
      case 'dynamicInput':
        return (
          <DynamicInputQuestion
            question={question}
            value={answers[question.id]}
            onChange={handleAnswer}
          />
        );
      case 'colorPicker':
        return (
          <ColorPickerQuestion
            question={question}
            value={answers[question.id]}
            onChange={handleAnswer}
          />
        );
      default:
        return null;
    }
  };

  const progressPercentage = (currentQuestion / (questions.length - 1)) * 100;

  return (
    <div className="relative min-h-screen text-white overflow-hidden">
      <ParticleBackground />
      <div className="container mx-auto px-4 py-12 relative z-10">
        <div className="bg-gray-800 bg-opacity-90 rounded-2xl p-8 shadow-2xl max-w-4xl mx-auto backdrop-filter backdrop-blur-lg">
          <div className="mb-8">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium text-blue-300">Question {currentQuestion + 1} of {questions.length}</span>
              <span className="text-sm font-medium text-blue-300">{Math.round(progressPercentage)}% Complete</span>
            </div>
            <div className="w-full bg-gray-700 rounded-full h-2">
              <div
                className="bg-blue-500 h-2 rounded-full"
                style={{ width: `${progressPercentage}%` }}
              />
            </div>
          </div>

          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              {questions[currentQuestion].icon}
              <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
                {questions[currentQuestion].question}
              </h2>
            </div>
            <p className="text-gray-300 italic">
              {questions[currentQuestion].helpText}
            </p>
            <div>
              {renderQuestion(questions[currentQuestion])}
            </div>
          </div>

          <div className="mt-8 flex justify-between items-center">
            <button
              onClick={handlePrevious}
              className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-6 rounded-full transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center"
              disabled={currentQuestion === 0}
            >
              <ChevronDown className="mr-2 rotate-90" size={20} />
              Previous
            </button>
            {currentQuestion < questions.length - 1 ? (
              <button
                onClick={handleNext}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full transition duration-300 flex items-center focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Next
                <ArrowRight className="ml-2" size={20} />
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-full transition duration-300 flex items-center focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                {isSubmitting ? (
                  <>
                    <div className="mr-2">
                      <ChevronDown size={20} />
                    </div>
                    Submitting...
                  </>
                ) : (
                  <>
                    Submit
                    <Check className="ml-2" size={20} />
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>

      <button
        onClick={() => setIsHelpModalOpen(true)}
        className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full flex items-center space-x-2 focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-lg"
      >
        <HelpCircle size={20} />
        <span>Need Help?</span>
      </button>

      <HelpModal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} />
    </div>
  );
};

export default AppIdeaQuestionnaire;