import React, { useState, useEffect } from 'react';
import { CheckCircle, Zap, Map, Code, HourglassIcon, Clock, Upload } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ParticleBackground from './ParticleBackground';
import { useAppDetails } from '../contexts/AppDetailsContext';
import AppIdeaQuestionnaire from './AppIdeaQuestionnaire';
import { useAuth } from '../contexts/AuthContext';
import { db, storage } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
const phases = [
    {
        name: 'Ignition',
        icon: <Zap size={24} />,
        color: '#FFA500',
        textColor: '#FFF',
        steps: [
            { 
                name: 'Ignition Questionnaire', 
                component: AppIdeaQuestionnaire,
                responsibility: 'client',
                description: "Fill out a detailed questionnaire about your app idea.",
                estimatedTime: '30-45 minutes',
                requiresApproval: false

            },
            { 
                name: 'Logo Design', 
                component: null,
                responsibility: 'company',
                description: "Our designers will create a unique logo for your app.",
                estimatedTime: '3-5 business days',
                requiresApproval: true

            },
            { 
                name: 'Splash Screen Design', 
                component: null,
                responsibility: 'company',
                description: "We'll design an eye-catching splash screen for your app.",
                estimatedTime: '1-2 business days',
                requiresApproval: true

            },
            { 
                name: 'Concept Screens', 
                component: null,
                responsibility: 'company',
                description: "We'll create initial concept screens for key app features.",
                estimatedTime: '3-5 business days',
                requiresApproval: true

            },
        ],
    },
    {
        name: 'Mapping',
        icon: <Map size={24} />,
        color: '#4CAF50',
        textColor: '#FFF',
        steps: [
            { 
                name: 'Functionality Expansion', 
                component: null,
                responsibility: 'both',
                description: "We'll work together to expand on your app's functionality.",
                estimatedTime: '2-3 business days'
            },
            { 
                name: 'PRD Generation', 
                component: null,
                responsibility: 'company',
                description: "We'll create a detailed Product Requirements Document.",
                estimatedTime: '5-7 business days'
            },
            { 
                name: 'UIUX Design', 
                component: null,
                responsibility: 'company',
                description: "Our designers will create the full UI/UX for your app.",
                estimatedTime: '7-10 business days'
            },
            { 
                name: 'Marketing Video Creation', 
                component: null,
                responsibility: 'company',
                description: "We'll produce a marketing video to showcase your app.",
                estimatedTime: '5-7 business days'
            },
        ],
    },
    {
        name: 'Coding',
        icon: <Code size={24} />,
        color: '#2196F3',
        textColor: '#FFF',
        steps: [
            { 
                name: 'BRS Document Creation', 
                component: null,
                responsibility: 'company',
                description: "We'll create a detailed Business Requirements Specification.",
                estimatedTime: '5-7 business days'
            },
            { 
                name: 'App Development', 
                component: null,
                responsibility: 'company',
                description: 'Our developers will build your app based on the approved designs and specifications.',
                estimatedTime: '4-8 weeks'
            },
            { 
                name: 'App Store Submission', 
                component: null,
                responsibility: 'company',
                description: "We'll prepare and submit your app to the relevant app stores.",
                estimatedTime: '1-2 weeks'
            },
        ],
    },
];

const AppDevelopmentProcess = () => {
    const [selectedPhase, setSelectedPhase] = useState(phases[0]);
    const [setSelectedStep] = useState(null);
    const navigate = useNavigate();
    const { currentUser, loading: authLoading } = useAuth();
    const { completedSteps, loading } = useAppDetails();
    const [uploadingFile, setUploadingFile] = useState(false);

    useEffect(() => {
      console.log("AppDevelopmentProcess: completedSteps updated", completedSteps);
      console.log("AppDevelopmentProcess: loading state", loading);
    }, [completedSteps, loading]);

    const handleStepSelect = (step) => {
      if (step.name === 'Ignition Questionnaire') {
        if (isStepCompleted(step.name)) {
          navigate('/results');
        } else {
          navigate('/questionnaire');
        }
      } else {
        setSelectedStep(step);
      }
    };

    const isStepCompleted = (stepName) => {
      return completedSteps && completedSteps.includes(stepName);
    };

    const canStartStep = (step, index) => {
      if (step.responsibility === 'company') {
        return false;
      }
      if (index === 0) {
        return true;
      }
      const previousStep = selectedPhase.steps[index - 1];
      return isStepCompleted(previousStep.name) && (!previousStep.requiresApproval || isStepApproved(previousStep.name));
    };

    const isStepApproved = (stepName) => {
      // Implement this function to check if a step has been approved by the client
      // You'll need to update your data structure to store approval status
      return false; // Placeholder
    };

    const handleFileUpload = async (event, step) => {
      if (authLoading) {
        console.log('Auth is still loading, cannot upload file.');
        return;
      }

      if (!currentUser) {
        console.error('Cannot upload file: User is not logged in.');
        return;
      }
  
      const file = event.target.files[0];
      if (!file) return;
  
      setUploadingFile(true);
  
      try {
        const fileRef = ref(storage, `users/${currentUser.uid}/${step.name}/${file.name}`);
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
  
        // Update the user's document with the file URL
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, {
          [`stepFiles.${step.name}`]: downloadURL
        });
  
        alert('File uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file. Please try again.');
      } finally {
        setUploadingFile(false);
      }
    };

    if (authLoading || loading) {
      return (
        <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
          Loading...
        </div>
      );
    }

    return (
      <div className="relative min-h-screen p-8 bg-gray-900 text-white">
        <ParticleBackground />
        <div className="relative z-10">
          <h1 className="text-2xl font-bold mb-12 text-center text-white">
            Dashboard
          </h1>
  
          {/* Phase Progress */}
          <div className="flex justify-between mb-16 bg-gray-900 p-4 rounded-lg">
            {phases.map((phase, index) => (
              <div key={phase.name} className="flex flex-col items-center">
                <button
                  className={`w-16 h-16 rounded-full flex items-center justify-center ${
                    selectedPhase.name === phase.name ? 'bg-blue-500' : 'bg-gray-700'
                  }`}
                  onClick={() => setSelectedPhase(phase)}
                >
                  {React.cloneElement(phase.icon, { color: 'white' })}
                </button>
                <span className="mt-2 font-medium text-sm">{phase.name}</span>
              </div>
            ))}
          </div>
  
          {/* Step List */}
          <div className="space-y-2 mb-12">
            {selectedPhase.steps.map((step, index) => (
              <div
                key={step.name}
                className={`p-4 rounded-lg ${
                  isStepCompleted(step.name)
                    ? 'bg-green-600 bg-opacity-40'
                    : canStartStep(step, index)
                    ? 'bg-gray-800 bg-opacity-60'
                    : 'bg-gray-700 bg-opacity-40'
                } hover:bg-opacity-80 transition-all duration-200 cursor-pointer border border-gray-700`}
                onClick={() => canStartStep(step, index) && handleStepSelect(step)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="mr-3">
                      {isStepCompleted(step.name) ? (
                        <CheckCircle size={20} className="text-green-400" />
                      ) : step.responsibility === 'company' ? (
                        <HourglassIcon size={20} className="animate-pulse" style={{ color: '#FFA500' }}/>
                      ) : (
                        <div className="w-5 h-5 rounded-full border-2 border-gray-500" />
                      )}
                    </div>
                    <span className="text-lg font-medium">{step.name}</span>
                  </div>
                  {!isStepCompleted(step.name) && step.responsibility === 'company' && (
                    <span className="text-xs text-blue-300">Pending Company</span>
                  )}
                </div>
                <p className="text-sm text-gray-400 mt-2 ml-8">{step.description}</p>
                <div className="flex items-center text-xs text-gray-500 mt-2 ml-8">
                  <Clock size={14} className="mr-1" />
                  <span>{step.estimatedTime}</span>
                </div>
                {step.responsibility === 'company' && isStepCompleted(step.name) && (
                  <div className="mt-2 ml-8">
                    <input
                      type="file"
                      id={`file-upload-${step.name}`}
                      className="hidden"
                      onChange={(e) => handleFileUpload(e, step)}
                      disabled={uploadingFile}
                    />
                    <label
                      htmlFor={`file-upload-${step.name}`}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <Upload size={16} className="mr-2" />
                      {uploadingFile ? 'Uploading...' : 'Upload File'}
                    </label>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default AppDevelopmentProcess;