import React, { useState, useCallback } from 'react';
import { ChevronDown, Target, Zap, Palette, Cpu, Rocket, User, Smartphone, Globe, MessageCircle, RefreshCw } from 'lucide-react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AppBlueprintPDF from './AppBlueprintPDF';
import { useAppDetails } from '../contexts/AppDetailsContext';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ParticleBackground from './ParticleBackground';

function ResultsDisplay() {
  const { appDetails, resetQuestionnaire } = useAppDetails();
  const [expandedSection, setExpandedSection] = useState(null);
  const navigate = useNavigate();
  const { currentUser, authLoading } = useAuth();

  const toggleSection = useCallback(
    (index) => {
      setExpandedSection((prevExpanded) => (prevExpanded === index ? null : index));
    },
    [] // Add dependencies if needed
  );

  const handleRedoQuestionnaire = async () => {
    await resetQuestionnaire();
    navigate('/questionnaire');
  };

  if (authLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <p className="text-xl">Loading...</p>
      </div>
    );
  }

  if (!appDetails) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <p className="text-xl">No app details available. Please complete the questionnaire first.</p>
      </div>
    );
  }

  const {
    userName = currentUser?.displayName || "Anonymous",
    appName = "Unnamed App",
    appSummary = "No summary available",
    appSummarySuggestions = [],
    targetAudience = "No target audience specified",
    targetAudienceSuggestions = [],
    keyFeatures = [],
    keyFeaturesSuggestions = [],
    designPreferences = "No design preferences specified",
    designSuggestions = [],
    technicalConsiderations = "No technical considerations specified",
    technicalSuggestions = [],
    nextSteps = [],
    nextStepsSuggestions = [],
    platforms = []
  } = appDetails;

  const sections = [
    { title: "Creator", content: userName, icon: <User size={24} className="text-blue-400" /> },
    { title: "App Name", content: appName, icon: <Smartphone size={24} className="text-purple-400" /> },
    { title: "Target Platforms", content: platforms, icon: <Globe size={24} className="text-green-400" /> },
    { title: "App Summary", content: appSummary, icon: <Zap size={24} className="text-yellow-400" />, suggestions: appSummarySuggestions },
    { title: "Target Audience", content: targetAudience, icon: <Target size={24} className="text-red-400" />, suggestions: targetAudienceSuggestions },
    { title: "Key Features", content: keyFeatures, icon: <Zap size={24} className="text-blue-400" />, suggestions: keyFeaturesSuggestions },
    { title: "Design Preferences", content: designPreferences, icon: <Palette size={24} className="text-purple-400" />, suggestions: designSuggestions },
    { title: "Technical Considerations", content: technicalConsiderations, icon: <Cpu size={24} className="text-green-400" />, suggestions: technicalSuggestions },
    { title: "Next Steps", content: nextSteps, icon: <Rocket size={24} className="text-yellow-400" />, suggestions: nextStepsSuggestions }
  ];

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 relative overflow-hidden bg-gray-900">
      <ParticleBackground />
      <button
        onClick={() => navigate('/')}
        className="absolute top-4 left-4 text-white bg-transparent hover:bg-blue-600 px-4 py-2 rounded z-10"
      >
        Dashboard
      </button>
      <div className="absolute inset-0 bg-[url('/public/diagonal-lines.svg')] opacity-25"></div>
      <div className="max-w-4xl mx-auto relative z-10">
        <div className="text-center mb-12">
          <h1 className="text-6xl font-bold bg-gradient-to-r from-blue-400 to-purple-600 bg-clip-text text-transparent">
            Ignition Phase
          </h1>
          <p className="mt-3 text-xl text-gray-300">
            Your App Blueprint
          </p>
        </div>

        <div className="space-y-4">
          {sections.map((section, index) => (
            <Section
              key={index}
              title={section.title}
              content={section.content}
              icon={section.icon}
              suggestions={section.suggestions}
              isExpanded={expandedSection === index}
              onClick={() => toggleSection(index)}
            />
          ))}
        </div>

        <div className="mt-12 flex justify-center space-x-4">
          <PDFDownloadLink
            document={<AppBlueprintPDF appDetails={appDetails} logoUrl="/companyLogo.png" />}
            fileName="app_blueprint.pdf"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300"
          >
            {({ blob, url, loading, error }) => (
              loading ? 'Generating PDF...' : 'Download PDF'
            )}
          </PDFDownloadLink>
          <button
            onClick={handleRedoQuestionnaire}
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300"
          >
            <RefreshCw size={20} className="mr-2" />
            Redo Questionnaire
          </button>
        </div>
      </div>
    </div>
  );
}

const Section = React.memo(({ title, content, icon, suggestions, isExpanded, onClick }) => (
  <div className="bg-gray-800 bg-opacity-50 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300">
    <button
      className="w-full text-left p-6 flex items-center justify-between focus:outline-none"
      onClick={onClick}
    >
      <div className="flex items-center space-x-4">
        <div className="p-2 bg-gray-700 rounded-full">
          {icon}
        </div>
        <h3 className="text-xl font-semibold text-white">{title}</h3>
      </div>
      <ChevronDown
        size={24}
        className={`text-gray-400 transition-transform duration-300 ${isExpanded ? 'transform rotate-180' : ''}`}
      />
    </button>
    {isExpanded && (
      <div className="px-6 pb-6">
        <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg">
          {Array.isArray(content) ? (
            <ul className="list-disc pl-5 space-y-2 text-gray-300">
              {content.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-300">{content}</p>
          )}
          {suggestions && suggestions.length > 0 && (
            <div className="mt-4">
              <h4 className="text-lg font-semibold text-blue-400 flex items-center">
                <MessageCircle size={20} className="mr-2" />
                AI Suggestions
              </h4>
              <ul className="list-disc pl-5 space-y-2 text-gray-400 mt-2">
                {suggestions.map((suggestion, index) => (
                  <li key={index}>{suggestion}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
));

export default ResultsDisplay;