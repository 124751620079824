import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font, Svg, Path } from '@react-pdf/renderer';

// Register custom fonts
Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', fontWeight: 300 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf', fontWeight: 500 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#f0f4f8',
    padding: 40,
    fontFamily: 'Roboto',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 40,
  },
  logo: {
    width: 120,
    height: 'auto',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 28,
    fontWeight: 700,
    color: '#1a365d',
    textAlign: 'center',
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 500,
    color: '#2c5282',
    textAlign: 'center',
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
    lineHeight: 1.5,
    color: '#2d3748',
    textAlign: 'center',
    marginBottom: 5,
  },
  platformsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  platformItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  platformIcon: {
    width: 24,
    height: 24,
    marginRight: 5,
  },
  section: {
    marginBottom: 20,
    padding: 15,
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: '#2c5282',
    marginBottom: 10,
    borderBottom: '2 solid #e2e8f0',
    paddingBottom: 5,
  },
  sectionContent: {
    fontSize: 12,
    lineHeight: 1.5,
    color: '#2d3748',
    textAlign: 'left', // Ensure left alignment
  },
  list: {
    marginLeft: 15,
  },
  listItem: {
    fontSize: 12,
    lineHeight: 1.5,
    marginBottom: 5,
    textAlign: 'left', // Ensure left alignment for list items
  },
  image: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    marginBottom: 20,
    borderRadius: 4,
  },
  suggestionBox: {
    backgroundColor: '#e6fffa',
    borderRadius: 4,
    padding: 10,
    marginTop: 10,
  },
  suggestionTitle: {
    fontSize: 14,
    fontWeight: 700,
    color: '#234e52',
    marginBottom: 5,
  },
  suggestion: {
    fontSize: 11,
    color: '#2c7a7b',
    marginBottom: 3,
  },
});

// SVG icons for platforms
const AppleIcon = () => (
  <Svg viewBox="0 0 384 512" width={24} height={24}>
    <Path fill="#000000" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
  </Svg>
);

const AndroidIcon = () => (
  <Svg viewBox="0 0 576 512" width={24} height={24}>
    <Path fill="#3DDC84" d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"/>
  </Svg>
);

const AppBlueprintPDF = ({ appDetails, logoUrl }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src={logoUrl} style={styles.logo} />
      </View>
      <View style={styles.content}>
        <Text style={styles.title}>Your App Idea Blueprint</Text>
        <Text style={styles.subtitle}>{appDetails.appName}</Text>
        <Text style={styles.text}>Created by: {appDetails.userName}</Text>
        <Text style={styles.text}>Date: {new Date().toLocaleDateString()}</Text>
        <View style={styles.platformsContainer}>
          {appDetails.platforms.includes('iPhone (iOS)') && (
            <View style={styles.platformItem}>
              <AppleIcon />
            </View>
          )}
          {appDetails.platforms.includes('Android phones') && (
            <View style={styles.platformItem}>
              <AndroidIcon />
            </View>
          )}
        </View>
        {/* <Text style={[styles.text, { marginTop: 20, fontWeight: 500 }]}>Target Platforms:</Text>
        {appDetails.platforms.map((platform, index) => (
          <Text key={index} style={styles.text}>• {platform}</Text>
        ))} */}
      </View>
    </Page>

    <SectionPage
      title="App Summary"
      content={appDetails.appSummary}
      suggestions={appDetails.appSummarySuggestions}
      imagePlaceholder="Magicon1.png"
    />

    <SectionPage
      title="Target Audience"
      content={appDetails.targetAudience}
      suggestions={appDetails.targetAudienceSuggestions}
      imagePlaceholder="Magicon2.png"
    />

    <SectionPage
      title="Key Features"
      content={appDetails.keyFeatures}
      suggestions={appDetails.keyFeaturesSuggestions}
      imagePlaceholder="Magicon3.png"
    />

    <SectionPage
      title="Design Preferences"
      content={appDetails.designPreferences}
      suggestions={appDetails.designSuggestions}
      imagePlaceholder="Magicon4.png"
    />

    <SectionPage
      title="Technical Considerations"
      content={appDetails.technicalConsiderations}
      suggestions={appDetails.technicalSuggestions}
      imagePlaceholder="Magicon6.png"
    />

    <SectionPage
      title="Next Steps"
      content={appDetails.nextSteps}
      suggestions={appDetails.nextStepsSuggestions}
      imagePlaceholder="Magicon7.png"
    />
  </Document>
);

const SectionPage = ({ title, content, suggestions, imagePlaceholder }) => (
  <Page size="A4" style={styles.page}>
    <View style={styles.section}>
      <Image src={imagePlaceholder} style={styles.image} />
      <Text style={styles.sectionTitle}>{title}</Text>
      {Array.isArray(content) ? (
        <View style={styles.list}>
          {content.map((item, index) => (
            <Text key={index} style={styles.listItem}>• {item}</Text>
          ))}
        </View>
      ) : (
        <Text style={styles.sectionContent}>{content}</Text>
      )}
      <View style={styles.suggestionBox}>
        <Text style={styles.suggestionTitle}>AI Suggestions:</Text>
        {suggestions.map((suggestion, index) => (
          <Text key={index} style={styles.suggestion}>• {suggestion}</Text>
        ))}
      </View>
    </View>
  </Page>
);

export default AppBlueprintPDF;